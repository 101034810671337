* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

header {
  width: 100%;
  height: 8%;
  padding: 30px 2% 10px;
  background-color: #f3f3f3;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 10;

  border-bottom: 1px solid var(--bg-tertiary-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

h1 {
  margin: 0;
  padding: 0;
}

.title-tozaisen {
  text-align: left;
  margin-bottom: 2px;
}

.title-kenkyu {
  text-align: right;
}

.header_a {
  text-decoration: none;
  background: linear-gradient(#0000 70%, #f0c95e 0);
  color: #3d3d3d;
  font-size: 1.6rem;
}

.header_b {
  text-decoration: none;
  color: #5f5f5f;
  font-size: 1.1rem;
}

ul {
  list-style: none;
  margin: 0;
  display: flex;
}

li {
  margin: 0 0 0 15px;
  font-size: 20px;
}

nav {
  margin: 0 0 0 auto;
  padding-right: 2%;
  padding-top: 1%;
}
