@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP");
@import url("https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c");

html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: ui-monospace;
}
