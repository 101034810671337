@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");

.property_table {
  margin-left: auto;
  margin-right: 4%;
  border-collapse: collapse;
}

h2 {
  font-size: 1.6rem;
  color: #3d3d3d;
  padding-left: 5%;
}

h4 {
  font-size: 1.1rem;
  color: #535353;
  padding-left: 5%;
}

table th {
  background-color: #4a4a4a;
  color: #fefefe;
  font-weight: 500;
  padding: 0.2rem;
}

table,
table th,
table td {
  border: 1.8px solid #a09f9f;
}

table td {
  letter-spacing: 0.1em;
  padding: 0.15rem;
  max-width: 21rem;
}

.osusume {
  text-align: center;
  font-family: inherit;
}

.text-right {
  text-align: right;
}
.projected_rent {
  color: #9b1818;
  font-weight: bold;
}

.bukken_url {
  min-width: 20rem;
}

.total_fee {
  font-weight: bold;
}

table th {
  letter-spacing: 0.2rem;
}

.contents_box {
  display: flex;
  width: auto;
}

.right_div {
  display: block;
  width: 20%;
  min-width: 310px;
  max-width: 310px;
}

.properties_content {
  font-size: medium;
}

.properties_title {
  padding-left: 0%;
}

.search_block1 {
  width: 15%;
  position: absolute;
  top: 30%;
  right: 10%;
  z-index: 1;
  background-color: #e6e6e6;
  border-radius: 5px 5px 5px 5px;
  border-top: 5px solid #4a4a4a;
  border-right: 5px solid #7a7a78;
  border-left: 1px solid #7a7a78;
  border-bottom: 1px solid #7a7a78;
}

.search_block2 {
  display: none;
}

.search_title {
  background-color: #4a4a4a;
  height: 10%;
  margin-top: 0;
  color: white;
  font-size: 1.2rem;
}

.block_title {
  font-weight: bold;
  font-size: 0.95rem;
  margin-bottom: 3%;
  text-align: left;
  border-left: 5px solid #50ae47;
  margin-left: 5%;
  margin-top: 0%;
  width: 70%;
  padding-left: 2%;
}

.sub_block_title {
  font-weight: bold;
  font-size: 0.95rem;
  margin-bottom: 5%;
  text-align: left;
  border-left: 5px solid #a1a9a3;
  margin-left: 5%;
  margin-top: 5%;
  width: 70%;
  padding-left: 2%;
}

.access_time_block {
  margin-top: 10%;
  margin-bottom: 15%;
}

.search_button {
  width: 75%;
  height: 2rem;
  margin-bottom: 5%;
  background-color: #4a4a4a;
  border-bottom: 2px solid #033c6b;
  border-radius: 5vh;
  color: #fff;
  font-size: 1rem;
}
.search_button:hover {
  background-color: #6b6b6b;
}

.selectbox-002 {
  position: relative;
}

.selectbox-002::before,
.selectbox-002::after {
  position: absolute;
  content: "";
  pointer-events: none;
  z-index: 1;
}

.selectbox-002::before {
  right: 0;
  display: inline-block;
  width: 2.8em;
  height: 2.8em;
  border-radius: 0 3px 3px 0;
  background-color: #8d8d8d;
  content: "";
}

.selectbox-002::after {
  position: absolute;
  top: 50%;
  right: 1.4em;
  transform: translate(50%, -50%) rotate(45deg);
  width: 6px;
  height: 6px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  content: "";
}

.selectbox-002 select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.8em;
  padding: 0.4em 3.6em 0.4em 0.8em;
  border: 2px solid #4a4a4a;
  border-radius: 3px;
  color: #333333;
  font-size: 1em;
  cursor: pointer;
}

.selectbox-002 select:focus {
  outline: 1px solid #2589d0;
}

.double_form_block {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 7%;
  margin-top: 2%;
}

.deco {
  background: linear-gradient(#0000 80%, #ffb3c4 0);
}

.website_title {
  background: linear-gradient(#0000 90%, #bb8f4c 0);
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 auto;
  width: 12rem;
}

.website_explain {
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 5%;
}

.st_list_block {
  width: 15%;
  height: 20%;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 10%;
  border-radius: 5px 5px 5px 5px;
}
.ul_st-list {
  list-style-type: square;
  display: block;
  padding-left: 0;
}
.st_list_title {
  text-align: left;
  padding-bottom: 6%;
  color: #1f1f1f;
  font: bold;
}

.li_st-list {
  text-align: left;
  padding-bottom: 6%;
  list-style: none;
}

.a_st-list {
  color: #000000;
  font-size: 0.9rem;
}

.contact {
  font-weight: 700;
}

.search_block3 {
  display: none;
}
/* ####################################################################################################################### */
/* ####################################################################################################################### */

@media screen and (max-width: 3000px) {
  .contents_box {
    display: flex;
    margin-top: 7%;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    min-width: 1400px;
    justify-content: center;
  }

  .properties_content {
    font-size: 0.75rem;
    width: 60%;
    min-width: 1100px;
    font-weight: 700;
  }

  .search_block1 {
    width: 100%;
    position: static;
    margin-top: 7%;
    margin-bottom: 20%;
    top: 0%;
    right: 0%;
    z-index: 1;
    border-radius: 5px 5px 5px 5px;
    border-top: 5px solid #50ae47;
    border-right: 5px solid #4a4a4a;
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  .station_block,
  .access_time_block {
    padding: 0%;
    margin-bottom: 6%;
  }

  .selectbox-002 {
    position: relative;
  }

  .selectbox-002::before,
  .selectbox-002::after {
    position: absolute;
    content: "";
    pointer-events: none;
    z-index: 1;
  }

  .selectbox-002::before {
    right: 0;
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 0 3px 3px 0;
    background-color: #646363;
    content: "";
  }

  .selectbox-002::after {
    position: absolute;
    top: 40%;
    right: 1.4em;
    transform: translate(85%, -40%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    content: "";
  }

  .selectbox-002 select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 13rem;
    height: 2.2rem;
    padding: 0.2em 3.6em 0.2em 0.8em;
    border-radius: 3px;
    color: #333333;
    font-size: 0.85rem;
    cursor: pointer;
    font-weight: bold;
  }

  .selectbox-002 select:focus {
    outline: 1px solid #2589d0;
  }

  .selectbox-003 {
    position: relative;
  }

  .selectbox-003::before,
  .selectbox-003::after {
    position: absolute;
    content: "";
    pointer-events: none;
    z-index: 1;
  }

  .selectbox-003::before {
    right: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 0 3px 3px 0;
    background-color: #646363;
    content: "";
  }

  .selectbox-003::after {
    position: absolute;
    top: 40%;
    right: 1.2em;
    transform: translate(85%, -40%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    content: "";
  }

  .selectbox-003 select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 8rem;
    max-width: 95%;
    height: 2rem;
    padding: 0.2em 3.6em 0.2em 0.8em;
    border-radius: 3px;
    color: #333333;
    font-size: 0.8rem;
    cursor: pointer;
    border-color: #bbbbbb;
    font-weight: bold;
  }

  .selectbox-003 select:focus {
    outline: 1px solid #2589d0;
  }

  .search_button {
    width: 65%;
    min-width: 190px;
    margin-bottom: 5%;
    margin-top: 3%;
    border-bottom: 2px solid #000000;
    color: #fff;
  }

  .website_block {
    background-color: #ffe9e9;
    width: 100%;
    position: static;
    margin-bottom: 10%;
    font-size: 0.9rem;
  }

  .box8 {
    padding: 0.5em 1em;
    margin: 2em 0;
    color: #232323;
    background: #fff1cf;
    border-left: solid 10px #ffc06e;
    border-radius: 5px;
  }
  .box8 p {
    /* margin: 0; */
    padding: 0;
  }

  .st_list_block {
    width: 95%;
    height: 15%;
    position: static;
    top: 0%;
    right: 0%;
    z-index: 1;
    border-radius: 5px 5px 5px 5px;
  }
  .li_st-list {
    padding-bottom: 5%;
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 1800px) {
  .sub_block_title {
    font-size: 0.9rem;
  }

  .search_title {
    font-size: 1rem;
  }
  .sub_block_title {
    margin-bottom: 4%;
    margin-top: 0%;
  }
  .station_block {
    margin-bottom: 7%;
  }

  .search_button {
    margin-top: 3%;
    width: 60%;
    min-width: 180px;
    font-size: 0.9rem;
    height: 2rem;
    margin-bottom: 3%;
  }
  .selectbox-002 select {
    font-size: 0.8rem;
    font-weight: 700;
    height: 2rem;
    width: 12rem;
  }
  .selectbox-002:before {
    height: 2rem;
    width: 2rem;
  }
  .selectbox-002:after {
    transform: translate(110%, -40%) rotate(45deg);
  }
  .selectbox-003 select {
    font-size: 0.75rem;
    height: 1.8rem;
    width: 7.5rem;
  }
  .selectbox-003:before {
    height: 1.8rem;
    width: 1.8rem;
  }
  .li_st-list {
    font-size: 0.3rem;
  }
  .properties_content {
    font-size: 0.7rem;
  }
  .block_title {
    font-size: 0.9rem;
  }

  .website_block {
    font-size: 0.85rem;
  }
}

/* ####################################################################################################################### */
/* ####################################################################################################################### */

@media screen and (max-width: 1380px) {
  table th {
    font-weight: 900;
    padding: 0.25rem;
  }

  .contents_box {
    min-width: 0px;
  }

  .properties_content {
    font-size: 0.7rem;
    width: 72%;
    min-width: 900px;
    font-weight: 1000;
  }

  .bukken_url {
    min-width: 14rem;
  }

  .search_block1 {
    width: 100%;
  }
}

/* ####################################################################################################################### */
/* ####################################################################################################################### */

@media screen and (max-width: 840px) {
  /* 840px-760pxまでタブレットの画面を綺麗に見せること。 */
  header {
    width: 100%;
    height: 8%;
    padding: 2% 2% 2%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 10;
  }

  .header_a {
    text-decoration: none;
    font-size: 1.2rem;
    vertical-align: middle;
  }

  .header_b {
    text-decoration: none;
    color: #2d2d2d;
    font-size: 0.8rem;
    font-style: oblique;
    vertical-align: middle;
  }

  .contents_box {
    display: block;
    min-width: 0px;
    margin: 10% auto 2% auto;
  }

  .right_div {
    display: block;
    margin-right: 2%;
    width: 100%;
  }

  .properties_content {
    width: 95%;
    margin: 0 auto;
    padding-top: 0.5%;
    min-width: 700px;
  }

  .property_table {
    margin-left: auto;
    margin-right: auto;
    width: 99%;
  }

  .search_block1 {
    display: none;
  }

  .search_block2 {
    display: block;
    width: 95%;
    min-width: 680px;
    height: 1%;
    margin: 13% auto 0 auto;
    position: static;
    /* z-index: 1; */
    /* top: 40%;
    right: 10%; */
    background-color: #e8e8e8;
    border-radius: 5px 5px 5px 5px;
    border-top: 5px solid #4a4a4a;
    border-right: 5px solid #7a7a78;
    border-left: 1px solid #7a7a78;
    border-bottom: 1px solid #7a7a78;
  }

  .search_title {
    background-color: #4a4a4a;

    margin-top: 0;
    color: white;
    font-size: 1.3rem;
  }

  .form_group {
    display: block;

    flex-grow: 1;
  }

  .mini_flex {
    display: flex;
    justify-content: center;
    padding-bottom: 1%;
  }

  .block_title_mini {
    font-weight: 700;
    /* font-size: 1.2rem; */
    margin-bottom: 5%;
    text-align: left;
    border-left: 5px solid #50ae47;
    margin-left: 3%;
    margin-right: 3%;
    width: 5rem;
    padding-left: 2%;
  }

  .sub_block_title_mini {
    font-weight: bold;
    font-size: 0.95rem;
    margin-bottom: 5%;
    text-align: left;
    border-left: 5px solid #a1a9a3;
    width: 6rem;
    height: 3%;
    padding-left: 2%;
  }

  .rosen_title {
    margin-right: 4.7%;
  }

  .station_block {
    text-align: left;
    display: flex;
    padding: 1%;
  }

  .fee_block {
    text-align: left;
    display: flex;
    height: 3%;
    padding: 2% 3% 1% 1%;
  }

  .fee_two_block {
    text-align: left;
    display: flex;
    height: 3%;
    margin-right: 0;
    padding: 2% 1% 1% 1%;
  }

  .selectbox-003 {
    position: relative;
    margin-right: 10px;
  }

  .access_time_block {
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .search_button {
    width: 30%;
    min-width: 200px;
    margin-bottom: 3%;
    border-bottom: none;
    color: #fff;
    margin-top: 4%;
  }

  .selectbox-002 {
    position: relative;
    background-color: white;
  }

  .selectbox-002::before,
  .selectbox-002::after {
    position: absolute;
    content: "";
    pointer-events: none;
    z-index: 1;
  }

  .selectbox-002::before {
    right: 0;
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    border-radius: 0 3px 3px 0;
    content: "";
  }

  .selectbox-002::after {
    position: absolute;
    top: 40%;
    right: 1.4em;
    transform: translate(50%, -50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    content: "";
  }

  .selectbox-002 select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-width: 15rem;
    height: 2.5rem;
    padding: 0.4em 3.6em 0.4em 0.8em;

    border-radius: 3px;
    color: #333333;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .selectbox-002 select:focus {
    outline: 1px solid #2589d0;
  }

  .website_block {
    width: 95%;
    height: 50%;
    position: static;
    margin: 2% auto;
  }

  .website_explain {
    text-align: left;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 0%;
  }

  .st_list_block {
    display: none;
  }
  table,
  table th,
  table td {
    border: 1px solid black;
    letter-spacing: 0.1em;
    word-break: break-all;
    font-size: 0.7rem;
  }

  .header_kai {
    display: inline-block;
  }
}

/* ####################################################################################################################### */
/* ####################################################################################################################### */

@media screen and (max-width: 520px) {
  /* 414px-360pxまで快適に表示する */

  .website_title {
    font-size: 1.3rem;
  }

  .properties_content {
    width: 97%;
    min-width: 0;
  }

  .contents_box {
    margin: 25% auto 2% auto;
  }

  .station_block {
    text-align: left;
    display: flex;
    padding: 1%;
    justify-content: space-around;
    margin-bottom: 15px;
  }

  .search_block3 {
    border-color: #50ae47 #4a4a4a #4a4a4a #4a4a4a;
    border-radius: 5px 5px 5px 5px;
    border-style: solid;
    border-width: 5px 1px 1px 1px;
    display: block;
    height: 1%;
    margin: 13% auto 0;
    position: static;
    width: 94%;
    background-color: #e2e2e2;
    min-width: 310px;
  }

  .search_block2 {
    display: none;
    margin: 30% 3% 0;
    max-width: 600px;
    top: 50%;
  }

  h2 {
    font-size: 1.7rem;
  }

  h4 {
    font-size: 1rem;
  }

  .header_a {
    font-size: 1rem;
    padding: 0;
  }

  .block_title {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .search_title {
    font-size: 1.1rem;
  }

  table {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .double_form_block {
    margin-bottom: 15px;
  }
  .sub_block_title {
    margin-bottom: 13px;
  }
  .right_div {
    max-width: none;
  }
  .st_list_block {
    padding: 4%;
  }
  .header_kai {
    display: inline-block;
  }
  .selectbox-003 select {
    width: 9rem;
  }
  h2 {
    padding-left: 0;
  }
  h4 {
    padding-left: 0;
  }
}

/* ####################################################################################################################### */
/* ####################################################################################################################### */

@media screen and (max-width: 430px) {
  .header_a {
    font-size: 0.8rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  option {
    font-size: 1rem;
  }
  .contents_box {
    margin: 21% auto 2%;
  }

  .selectbox-002:before {
    height: 2rem;
    width: 2rem;
  }
  .selectbox-002 select {
    height: 2rem;
    min-width: 12rem;
    width: 12rem;
    font-size: 0.87rem;
  }
  .selectbox-002:after {
    top: 45%;
    transform: translate(120%, -39%) rotate(45deg);
  }

  .selectbox-003 select {
    height: 1.9rem;
    width: 7.5rem;
  }
  .sub_block_title {
    margin-bottom: 5px;
  }
  .selectbox-003:before {
    height: 1.9rem;
    width: 1.9rem;
  }
  .selectbox-003:after {
    top: 45%;
    transform: translate(98%, -40%) rotate(45deg);
  }
  .search_title {
    font-size: 1rem;
  }
  .block_title,
  .sub_block_title {
    font-size: 0.9rem;
  }
  .search_button {
    margin-top: 2%;
  }
}
